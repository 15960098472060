import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useOrderAttachmentsStore = defineStore('attachments', () => {
  const attachments = ref<File[]>([])

  const onAttachmentsChange = (files: File[]) => {
    attachments.value = files
  }

  const resetStore = () => {
    attachments.value = []
  }

  return {
    attachments,
    resetStore,
    onAttachmentsChange,
  }
})
