import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createNestedCrudEndpoints,
} from '@/core/utils'
import { TAttachment, TCustomer, TOrder, TOrderDetail, TPayment } from '.'
import { DataWithPagination } from '@/core/types'

export type TOrders = DataWithPagination<TOrder>

export const serviceConfig = {
  model: 'orders',
  version: 'v1',
  details: {
    model: 'details',
    version: 'v1',
  },
  attachments: {
    model: 'attachments',
    version: 'v1',
  },
  payments: {
    model: 'payments',
    version: 'v1',
  },
  catalogs: {
    customers: {
      model: 'customers',
      version: 'v1',
    },
  },
}

const { model: modelDetails, version: versionDetails } = serviceConfig.details
const { model: modelPayments, version: versionPayments } =
  serviceConfig.payments
const { model: modelAttachments, version: versionAttachments } =
  serviceConfig.attachments
const { customers } = serviceConfig.catalogs

const moduleServiceHandlers = createCrudEndpoints<TOrders, TOrder>(
  serviceConfig.model,
  serviceConfig.version,
)

const moduleDetailServiceHandlers = createNestedCrudEndpoints<
  TOrderDetail[],
  TOrderDetail
>(serviceConfig.model, modelDetails, versionDetails)

const modulePaymentServiceHandlers = createNestedCrudEndpoints<
  TPayment[],
  TPayment
>(serviceConfig.model, modelPayments, versionPayments)

const moduleAttachmentServiceHandlers = createNestedCrudEndpoints<
  TAttachment[],
  TAttachment
>(serviceConfig.model, modelAttachments, versionAttachments)

const customersCatalogService = createCatalogEndpoint<TCustomer>(
  customers.model,
  customers.version,
)

const catalogServiceHandlers = {
  customersCatalogService,
}

export {
  moduleServiceHandlers,
  moduleDetailServiceHandlers,
  moduleAttachmentServiceHandlers,
  modulePaymentServiceHandlers,
  catalogServiceHandlers,
}
