<template>
  <dot-dialog
    :visible="isOpen"
    :header="
      type == 'add' ? 'Agregar detalle de orden' : 'Editar detalle de orden'
    "
    :breakpoints="{ '1400px': '70vw', '1199px': '80vw', '575px': '90vw' }"
    appendTo="#modals-root"
    modal
    class="w-[30vw]"
    @update:visible="$emit('close')"
  >
    <div class="flex flex-col gap-4">
      <div>
        <label for="description">Descripción</label>
        <dot-textarea
          v-model="form.description"
          :class="['w-full', { 'p-invalid': Boolean(errors.description) }]"
        />
        <small v-if="Boolean(errors.description)" class="p-error">
          {{ errors.description }}
        </small>
      </div>
      <div>
        <label for="price">Precio</label>
        <dot-input-number
          v-model="form.price"
          mode="currency"
          currency="USD"
          locale="en-US"
          :placeholder="t('columns.PRICE')"
          :class="['w-full', { 'p-invalid': Boolean(errors.price) }]"
        />
        <small v-if="Boolean(errors.price)" class="p-error">
          {{ errors.price }}
        </small>
      </div>
    </div>

    <template #footer>
      <footer class="flex justify-between">
        <dot-button
          label="Cancelar"
          icon="pi pi-times"
          text
          @click="$emit('close')"
        />
        <dot-button
          :label="t('actions.SAVE')"
          icon="pi pi-save"
          @click="onAddRecord"
        />
      </footer>
    </template>
  </dot-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { baseProps } from '@/core'
import * as yup from 'yup'
import { TOrderDetail } from '@/modules/orders/types'

export default defineComponent({
  name: 'ModalUser',
  props: {
    ...baseProps,
    record: {
      type: Object as PropType<TOrderDetail>,
      default: () => ({}),
    },
  },
  emits: ['close', 'add', 'edit'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const validationSchema = yup.object({
      description: yup
        .string()
        .required(
          t('validation.REQUIRED', { attribute: t('columns.DESCRIPTION') }),
        ),
      price: yup
        .string()
        .required(t('validation.REQUIRED', { attribute: t('columns.PRICE') })),
    })

    const { errors, values, handleSubmit, resetForm, defineField } =
      useForm<TOrderDetail>({
        initialValues: {},
        validationSchema,
      })

    const [description] = defineField('description')
    const [price] = defineField('price')

    const onAddRecord = handleSubmit.withControlled((values) => {
      if (props.type == 'add') {
        emit('add', values)
        emit('close')
        return
      }
      emit('edit', values)
      emit('close')
    })

    watch(
      () => props.isOpen,
      () => {
        resetForm(
          {
            values: {
              ...props.record,
            },
          },
          { force: true },
        )
      },
    )

    return {
      errors,
      values,
      form: reactive({ description, price }),
      t,
      onAddRecord,
    }
  },
})
</script>
