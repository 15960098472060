// composable will be deprecated, please use useToQueryString
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useDebounceFn } from '@vueuse/core'
import { ref, watch } from 'vue'
// import { useRouter } from 'vue-router' discomment al related to router if you want to push the filters in the url

type FilterValue =
  | null
  | string
  | Record<string, string | Record<string, string>>

type WhereFilter = Record<string, FilterValue>

type WhereInFilter = Record<string, any[]>

type WhereBetweenFilter = Record<string, [string, string]>

type BetweenDatesFilter =
  | Record<string, [string, string]>
  | Record<string, any[]>

export type QueryFilters = {
  where?: WhereFilter
  whereIn?: WhereInFilter
  whereBetween?: WhereBetweenFilter
  betweenDates?: BetweenDatesFilter
  includes?: string
  orderBy?: string
}

export const useQueryFilters = (filter: QueryFilters) => {
  const isMounted = ref(false)
  const delay = ref(250)
  const filterStringParams = ref('')
  const filterParams = ref<Record<string, string>>({})

  // const router = useRouter()

  const debouncedParseQueryFilters = useDebounceFn(() => {
    parseQueryFilters()
  }, delay.value)

  const parseQueryFilters = () => {
    isMounted.value = true
    let filterParamsValue = ''
    let param = ''

    if (filter.where) {
      Object.keys(filter.where).forEach((value) => {
        if (value !== 'param') {
          filter.where?.[value] &&
            !Array.isArray(filter.where?.[value]) &&
            typeof filter.where?.[value] !== 'object' &&
            (filterParamsValue += `&${value}=*${filter.where?.[value]}*`)
          value = Array.isArray(filter.where?.[value])
            ? `,${(filter.where?.[value] as string)?.[0]}|${(
                filter.where?.[value] as string
              )?.[1]}`
            : value
          if (typeof filter.where?.[value] === 'object') {
            let aux = ''
            if (filter.where?.[value]) {
              Object.keys(filter.where?.[value]).forEach((e) => {
                // eslint-disable-next-line no-unsafe-optional-chaining
                if (typeof (filter.where?.[value])[e] === 'object') {
                  Object.keys(filter.where?.[value][e]).forEach((i) => {
                    aux += `,${value}.${e}.${i}`
                  })
                } else {
                  aux += `,${value}.${e}`
                  filterParamsValue += `&where_relation=${filter.where?.[value][e]}${aux}`
                }
              })
              value = aux.slice(1)
            }
          }
          param += `,${value}`
        } else {
          param &&
            filter.where?.[value] &&
            filter.where?.[value].length > 0 &&
            (filterParamsValue += `&param=${filter.where?.[value]}${param}`)
        }
      })
    }

    if (filter.whereIn) {
      Object.keys(filter.whereIn).forEach((value) => {
        filter.whereIn?.[value].forEach((el) => {
          filterParamsValue += `&${value}[]=${el}`
        })
      })
    }

    if (filter.whereBetween) {
      Object.keys(filter.whereBetween).forEach((value) => {
        if (
          filter.whereBetween?.[value] &&
          filter.whereBetween[value].length > 0
        ) {
          filterParamsValue += `&${value}>${filter.whereBetween[value][0]}`
          filterParamsValue += `&${value}<${filter.whereBetween[value][1]}`
        }
      })
    }

    if (filter.betweenDates) {
      Object.keys(filter.betweenDates).forEach((value) => {
        if (
          filter.betweenDates?.[value] &&
          filter.betweenDates[value].length > 0
        ) {
          filterParamsValue += `&between_dates=${filter.betweenDates[value][0]},${filter.betweenDates[value][1]},${value}`
        }
      })
    }

    if (filter.includes) {
      if (filter.includes.length > 0) {
        filterParamsValue += `&includes=${filter.includes}`
      }
    }

    if (filter.orderBy) {
      if (filter.orderBy.length > 0) {
        filterParamsValue += `&order_by=${filter.orderBy}`
      }
    }

    filterStringParams.value = filterParamsValue
    filterParams.value = convertFiltersToObject(filterParamsValue)
  }

  const convertFiltersToObject = (filters: string) => {
    const overriddenFilters = filters.split('&')

    const copyFilters = {}

    overriddenFilters.forEach((item) => {
      const [key, value] = item.split('=')
      if (key && value) {
        copyFilters[key] = value.trim()
      }
    })

    return copyFilters
  }

  // const setUrlPrams  = () => {
  // 	router.push({ query: filterParams.value })
  // }

  watch(
    filter,
    () => {
      !isMounted.value && parseQueryFilters()
      debouncedParseQueryFilters()
    },
    { immediate: true },
  )

  return { filterParams, filterStringParams }
}
