<template>
  <dot-dialog
    :visible="isOpen"
    :header="type == 'add' ? 'Agregar pago' : 'Editar pago'"
    :breakpoints="{ '1400px': '70vw', '1199px': '80vw', '575px': '90vw' }"
    appendTo="#modals-root"
    modal
    class="w-[30vw]"
    @update:visible="$emit('close')"
  >
    <div class="flex flex-col gap-4">
      <div>
        <label for="amount">Cantidad</label>
        <dot-input-number
          v-model="form.amount"
          mode="currency"
          currency="USD"
          locale="en-US"
          placeholder="Cantidad"
          :class="['w-full', { 'p-invalid': Boolean(errors.amount) }]"
        />
        <small v-if="Boolean(errors.amount)" class="p-error">
          {{ errors.amount }}
        </small>
      </div>
    </div>

    <template #footer>
      <footer class="flex justify-between">
        <dot-button
          label="Cancelar"
          icon="pi pi-times"
          text
          @click="$emit('close')"
        />
        <dot-button
          :label="t('actions.SAVE')"
          icon="pi pi-save"
          @click="onAddRecord"
        />
      </footer>
    </template>
  </dot-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { baseProps } from '@/core'
import * as yup from 'yup'
import { TPayment } from '@/modules/orders/types'

export default defineComponent({
  name: 'ModalPayments',
  props: {
    ...baseProps,
    record: {
      type: Object as PropType<TPayment>,
      default: () => ({}),
    },
  },
  emits: ['close', 'add', 'edit'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const validationSchema = yup.object({
      amount: yup.string().required('La cantidad del pago es requerido'),
    })

    const { errors, values, handleSubmit, resetForm, defineField } =
      useForm<TPayment>({
        initialValues: {},
        validationSchema,
      })

    const [amount] = defineField('amount')

    const onAddRecord = handleSubmit.withControlled((values) => {
      if (props.type == 'add') {
        emit('add', values)
        emit('close')
        return
      }
      emit('edit', values)
    })

    watch(
      () => props.isOpen,
      () => {
        resetForm(
          {
            values: {
              ...props.record,
            },
          },
          { force: true },
        )
      },
    )

    return {
      errors,
      values,
      form: reactive({ amount }),
      t,
      onAddRecord,
    }
  },
})
</script>
