import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authRoutes, dashboardRoutes, errorRoutes } from './modules'

const routes: Array<RouteRecordRaw> = [
  ...errorRoutes,
  ...authRoutes,
  ...dashboardRoutes,
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes,
})

export default router
