import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import {
  moduleDetailServiceHandlers,
  serviceConfig,
  TAttachment,
  TOrderDetail,
} from '../../..'
import { useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig.details

export const useDetailsQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, filterParams, parentId, enabled } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TAttachment>({
    model,
    currentPage: currentPage ?? 1,
    filterParams,
  })

  const addRecord = useMutation<TOrderDetail, AxiosError, TOrderDetail>(
    (payload: TOrderDetail) =>
      moduleDetailServiceHandlers.addRecord(payload, {
        modelId: parentId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<TOrderDetail, AxiosError, TOrderDetail>(
    (payload: TOrderDetail) =>
      moduleDetailServiceHandlers.editRecord(payload, {
        modelId: parentId,
        modelChildId: payload.id,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecord = useMutation<void, AxiosError, TOrderDetail>(
    (payload: TOrderDetail) =>
      moduleDetailServiceHandlers.deleteRecord({
        modelChildId: payload.id,
        modelId: parentId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TOrderDetail, AxiosError, TRecordAction>(
    (payload: TRecordAction) =>
      moduleDetailServiceHandlers.actionRecord(payload, {
        modelChildId: payload.id,
        modelId: parentId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TOrderDetail[], AxiosError>({
    queryKey: [model, parentId],
    queryFn: ({ pageParam }) =>
      moduleDetailServiceHandlers.loadRecords({
        page: pageParam,
        modelId: parentId,
      }),
    onError: (error) => errorHttpNotification(error),
    enabled: enabled?.loadRecords,
    refetchOnWindowFocus: false,
  })

  return {
    actionRecord,
    loadRecords,
    addRecord,
    editRecord,
    deleteRecord,
  }
}
