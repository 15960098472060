import { RouteRecordRaw } from 'vue-router'
import { usersModuleRoutes } from '@/modules/users'
import { dashboardModuleRoutes } from '@/modules/dashboard'
import { ordersModuleRoutes } from '@/modules/orders'
const DashboardLayout = () =>
  import(
    /* webpackChunkName: "dashboard-layout" */ '@/core/layouts/DashboardLayout.vue'
  )

export const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'orders' },
    component: DashboardLayout,
    meta: {
      auth: true,
    },
    children: [
      ...dashboardModuleRoutes,
      ...usersModuleRoutes,
      ...ordersModuleRoutes,
    ],
  },
]
