import { reactive, computed, onMounted } from 'vue'
import { createInjectionState } from '@vueuse/shared'
import { useLocalStorage } from '@vueuse/core'

type ThemeConfig = {
  mode: 'dark' | 'light'
}

const [useProvideTheme, useTheme] = createInjectionState(() => {
  const themeModeStorage = useLocalStorage<ThemeConfig['mode']>('mode', 'light')

  const themeConfig = reactive<ThemeConfig>({
    mode: 'light',
  })

  const changeThemeSettings = (mode: ThemeConfig['mode']) => {
    themeConfig.mode = mode
    themeModeStorage.value = mode
    onChangeTheme(mode)
  }

  const onChangeTheme = (mode: ThemeConfig['mode']) => {
    const elementId = 'theme-css'
    const linkElement = document.getElementById(elementId)
    const cloneLinkElement = linkElement?.cloneNode(
      true,
    ) as HTMLLinkElement | null
    const newThemeUrl = linkElement
      ?.getAttribute('href')
      ?.replace(mode == 'dark' ? 'light' : 'dark', mode)
    cloneLinkElement?.setAttribute('id', elementId + '-clone')
    cloneLinkElement?.setAttribute('href', newThemeUrl ?? '')
    cloneLinkElement?.addEventListener('load', () => {
      linkElement?.remove()
      cloneLinkElement.setAttribute('id', elementId)
    })
    if (linkElement && cloneLinkElement) {
      const parent = linkElement.parentNode
      if (parent) {
        parent.insertBefore(cloneLinkElement, linkElement.nextSibling)
      }
    }
  }

  const isDarkTheme = computed(() => themeConfig.mode === 'dark')
  const isLightTheme = computed(() => themeConfig.mode === 'light')

  onMounted(() => {
    if (themeModeStorage.value == 'dark') {
      changeThemeSettings(themeModeStorage.value)
    }
  })

  return {
    themeConfig,
    isDarkTheme,
    isLightTheme,
    changeThemeSettings,
  }
})

export { useProvideTheme, useTheme }
