import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, WORKER } = authRoles

const Orders = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

const OrdersCreate = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/create.vue')
const OrderDetail = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/detail.vue')

export const ordersModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    meta: {
      auth: {
        roles: [ADMIN, WORKER],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/orders/create',
    name: 'ordersCreate',
    component: OrdersCreate,
    meta: {
      auth: {
        roles: [ADMIN, WORKER],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/orders/:id',
    name: 'ordersDetail',
    component: OrderDetail,
    meta: {
      auth: {
        roles: [ADMIN, WORKER],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
