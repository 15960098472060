import type {
  BaseServiceOptions,
  Pagination,
  TImportFile,
  TRecordAction,
} from '@/core/types'
import {
  TCustomer,
  TOrder,
  TOrders,
  moduleServiceHandlers,
  serviceConfig,
  catalogServiceHandlers,
} from '../../..'
import { useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig
const { customers } = serviceConfig.catalogs
const { customersCatalogService } = catalogServiceHandlers
export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, filterParams, handlers, parentId, enabled } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TOrder>({
    model,
    currentPage: currentPage ?? 1,
    filterParams,
  })

  const deleteRecord = useMutation<void, AxiosError, TOrder>(
    (payload) => moduleServiceHandlers.deleteRecord(payload),
    {
      onSuccess: (_, user) => serviceHandlers.deleteRecord(user),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecords = useMutation<void, AxiosError, Array<number>>(
    (payload) => moduleServiceHandlers.deleteRecords({ ids: payload }),
    {
      onSuccess: (_, ids) => serviceHandlers.deleteRecords(ids),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<TOrder, AxiosError, TOrder>(
    (payload: TOrder) => moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TOrder, AxiosError, TOrder>(
    (payload: TOrder) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TOrder, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const importRecords = useMutation<void, AxiosError, TImportFile>(
    (payload: TImportFile) => {
      const formData = new FormData()
      formData.append('importFile', payload.file)
      return moduleServiceHandlers.importRecords(formData)
    },
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TOrders, AxiosError>({
    queryKey: [model, currentPage, filterParams],
    queryFn: () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filterParams,
        page: currentPage,
      }),
    refetchOnWindowFocus: false,
    enabled: enabled?.loadRecords,
    onError: (error) => errorHttpNotification(error),
    onSuccess: (data) => {
      if (data.meta) {
        handlers?.onDataChange?.(data.meta)
      }
    },
  })

  const loadRecord = useQuery<TOrder, AxiosError>({
    queryKey: [model, parentId],
    queryFn: ({ queryKey }) =>
      moduleServiceHandlers.loadRecord({
        id: Number(queryKey[1]),
      }),
    refetchOnWindowFocus: false,
    enabled: enabled?.loadRecord,
    onError: (error) => errorHttpNotification(error),
  })

  const customersCatalog = useQuery<TCustomer, AxiosError>({
    queryKey: [customers],
    queryFn: () => customersCatalogService.loadCatalog({}),
    refetchOnWindowFocus: false,
    enabled: enabled?.customersCatalog,
    onError: (error) => errorHttpNotification(error),
  })

  return {
    loadRecord,
    loadRecords,
    addRecord,
    editRecord,
    deleteRecords,
    deleteRecord,
    importRecords,
    actionRecord,
    customersCatalog,
  }
}
