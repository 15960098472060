import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { TPayment } from '../../types'

export const useOrderPaymentsStore = defineStore('payments', () => {
  const payments = ref<TPayment[]>([])

  const addPayment = (payment: TPayment) => {
    const id = Date.now()
    payments.value.push({ ...payment, id })
  }

  const deletePayment = (id: number) => {
    const index = payments.value.findIndex((p) => p.id === id)
    if (index !== -1) {
      payments.value.splice(index, 1)
    }
  }

  const resetStore = () => {
    payments.value = []
  }

  const totalPayments = computed(
    () =>
      payments.value?.reduce(
        (prev, curr) => prev + Number(curr?.amount ?? 0),
        0,
      ),
  )

  return {
    payments,
    totalPayments,
    resetStore,
    addPayment,
    deletePayment,
  }
})
