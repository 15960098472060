import { computed, onBeforeMount, ref, toValue } from 'vue'
import { createInjectionState } from '@vueuse/core'
import { useModuleQuery } from '../query/useModuleQuery'
import { useRoute } from 'vue-router'
import { TAttachment, TOrderDetail, TPayment } from '@/modules/orders/types'
import { useAttachmentQuery } from '../query/useAttachmentQuery'
import { useOrderAttachmentsStore } from '../../store/useOrderAttachmentsStore'
import { storeToRefs } from 'pinia'
import { useDetailsQuery } from '../query/useDetailsQuery'
import { TRecordAction } from '@/core/types'
import { usePaymentQuery } from '../query/usePaymentQuery'
const [useProvideOrderDetailModule, useOrderDetailModule] =
  createInjectionState(() => {
    const route = useRoute()

    const detailForm = ref<TOrderDetail>({})

    const attachmentsStore = useOrderAttachmentsStore()
    const { attachments } = storeToRefs(attachmentsStore)

    const moduleQuery = useModuleQuery({
      parentId: toValue(
        computed(() =>
          route.params?.id ? route.params?.id.toString() : undefined,
        ),
      ),
      enabled: {
        loadRecords: computed(() => false),
        loadRecord: computed(() => {
          const id = route.params?.id ? route.params?.id.toString() : undefined
          return !!id
        }),
        customersCatalog: computed(() => false),
      },
      filterParams: computed(() => ''),
    })

    const detailsQuery = useDetailsQuery({
      filterParams: computed(() => ''),
      parentId: toValue(
        computed(() =>
          route.params?.id ? route.params?.id.toString() : undefined,
        ),
      ),
      enabled: {
        loadRecords: computed(
          () => !moduleQuery.loadRecord.isLoading.value && !!route.params?.id,
        ),
      },
    })

    const attachmentQuery = useAttachmentQuery({
      filterParams: computed(() => ''),
      parentId: toValue(
        computed(() =>
          route.params?.id ? route.params?.id.toString() : undefined,
        ),
      ),
      enabled: {
        loadRecords: computed(
          () => !moduleQuery.loadRecord.isLoading.value && !!route.params?.id,
        ),
      },
    })

    const paymentQuery = usePaymentQuery({
      filterParams: computed(() => ''),
      parentId: toValue(
        computed(() =>
          route.params?.id ? route.params?.id.toString() : undefined,
        ),
      ),
      enabled: {
        loadRecords: computed(
          () => !moduleQuery.loadRecord.isLoading.value && !!route.params?.id,
        ),
      },
    })

    const onAdd = async (payload: TOrderDetail) => {
      await detailsQuery.addRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }

    const onEdit = async (payload: TOrderDetail) => {
      await detailsQuery.editRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }

    const onDelete = async (payload: TOrderDetail) => {
      await detailsQuery.deleteRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }

    const onAction = async (payload: TRecordAction) => {
      await detailsQuery.actionRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }

    const onDeliveryOrder = async (action: TRecordAction) => {
      await moduleQuery.actionRecord.mutateAsync(action)
      await moduleQuery.loadRecord.refetch()
      await detailsQuery.loadRecords.refetch()
    }

    const onAddAttachments = async () => {
      if (attachments.value.length) {
        const totalSize =
          attachments.value.reduce((prev, curr) => prev + curr.size, 0) /
          1000000
        if (totalSize < 21) {
          const formattedFiles = attachments.value.map((file: File) => ({
            file,
          }))

          await attachmentQuery.addRecord.mutateAsync({
            attachments: formattedFiles,
          })
        }
      }
      attachmentsStore.resetStore()
    }

    const onDeleteAttachment = async (attachment: TAttachment) => {
      await attachmentQuery.deleteRecord.mutateAsync(attachment)
    }

    const onDeletePayment = async (payload: TPayment) => {
      await paymentQuery.deleteRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }

    const onAddPayment = async (payload: TPayment) => {
      await paymentQuery.addRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }

    const model = computed(() => ({
      ...moduleQuery.loadRecord?.data?.value,
    }))
    const orderAttachments = computed(
      () => attachmentQuery.loadRecords.data.value,
    )
    const orderDetails = computed(() => detailsQuery.loadRecords.data.value)
    const orderPayments = computed(() => paymentQuery.loadRecords.data.value)
    const isLoading = computed(() => moduleQuery.loadRecord.isLoading.value)
    const isUploadingFiles = computed(
      () =>
        attachmentQuery.addRecord.isLoading.value && !!attachments.value.length,
    )

    onBeforeMount(() => {
      attachmentsStore.resetStore()
    })

    return {
      detailForm,
      model,
      orderAttachments,
      isLoading,
      isUploadingFiles,
      orderPayments,
      orderDetails,
      onDeliveryOrder,
      onAdd,
      onEdit,
      onAction,
      onDelete,
      onAddAttachments,
      onDeleteAttachment,
      onAddPayment,
      onDeletePayment,
    }
  })

export { useProvideOrderDetailModule, useOrderDetailModule }
