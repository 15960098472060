import type { BaseServiceOptions, Pagination } from '@/core/types'
import {
  modulePaymentServiceHandlers,
  serviceConfig,
  TAttachment,
  TOrderDetail,
} from '../../..'
import { useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig.payments

export const usePaymentQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, filterParams, parentId, enabled } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TAttachment>({
    model,
    currentPage: currentPage ?? 1,
    filterParams,
  })

  const addRecord = useMutation<TOrderDetail, AxiosError, TOrderDetail>(
    (payload: TOrderDetail) =>
      modulePaymentServiceHandlers.addRecord(payload, {
        modelId: parentId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecord = useMutation<void, AxiosError, TOrderDetail>(
    (payload: TOrderDetail) =>
      modulePaymentServiceHandlers.deleteRecord({
        modelChildId: payload.id,
        modelId: parentId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TOrderDetail[], AxiosError>({
    queryKey: [model, parentId],
    queryFn: ({ pageParam }) =>
      modulePaymentServiceHandlers.loadRecords({
        page: pageParam,
        modelId: parentId,
      }),
    onError: (error) => errorHttpNotification(error),
    enabled: enabled?.loadRecords,
    refetchOnWindowFocus: false,
  })

  return {
    loadRecords,
    addRecord,
    deleteRecord,
  }
}
