<template>
  <img v-if="variant === 'default'" :src="logo" alt="Logo" width="25px" />
  <img v-if="variant === 'dark'" :src="logoD" alt="Logo" width="25px" />
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import logo from '../../assets/images/logo-w.png'
import logoD from '../../assets/images/logo-b.png'
</script>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Logo',
  props: {
    variant: {
      type: String as PropType<'default' | 'dark'>,
      default: 'default',
    },
  },
})
</script>
