import type { BaseServiceOptions, Pagination } from '@/core/types'
import {
  moduleAttachmentServiceHandlers,
  serviceConfig,
  TAttachment,
  TOrder,
} from '../../..'
import { useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig.attachments

export const useAttachmentQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, filterParams, parentId, enabled } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TAttachment>({
    model,
    currentPage: currentPage ?? 1,
    filterParams,
  })

  const addRecord = useMutation<TAttachment, AxiosError, TOrder>(
    (payload: TOrder) =>
      moduleAttachmentServiceHandlers.addRecord(
        modelToFormData(payload),
        {
          modelId: parentId,
        },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      ),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecord = useMutation<void, AxiosError, TAttachment>(
    (payload) =>
      moduleAttachmentServiceHandlers.deleteRecord({
        modelChildId: payload.id,
        modelId: parentId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TAttachment[], AxiosError>({
    queryKey: [model, parentId],
    queryFn: ({ pageParam }) =>
      moduleAttachmentServiceHandlers.loadRecords({
        page: pageParam,
        modelId: parentId,
      }),
    onError: (error) => errorHttpNotification(error),
    enabled: enabled?.loadRecords,
    refetchOnWindowFocus: false,
  })

  const modelToFormData = (payload: TOrder) => {
    const formData = new FormData()

    payload?.attachments?.forEach(
      (record) => record.file && formData.append('files[]', record.file),
    )

    return formData
  }

  return {
    loadRecords,
    addRecord,
    deleteRecord,
  }
}
