import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { TOrderDetail } from '../../types'

export const useOrderDetailsStore = defineStore('orders', () => {
  const details = ref<TOrderDetail[]>([])

  const addDetail = (detail: TOrderDetail) => {
    const id = Date.now()
    details.value.push({ ...detail, id })
  }

  const deleteDetail = (id: number) => {
    const index = details.value.findIndex((d) => d.id === id)

    if (index !== -1) {
      details.value.splice(index, 1)
    }
  }

  const resetStore = () => {
    details.value = []
  }

  const totalDetails = computed(
    () =>
      details.value?.reduce((prev, curr) => prev + Number(curr?.price ?? 0), 0),
  )

  return {
    details,
    totalDetails,
    resetStore,
    addDetail,
    deleteDetail,
  }
})
