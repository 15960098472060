import { useLocalStorage } from '@vueuse/core'
import axios, { AxiosError, AxiosResponse } from 'axios'

const baseErrors = {
  INVALID_TOKEN: 'token is invalid',
  TOKEN_BLACKLISTED: 'The token has been blacklisted',
}

// Create Instances
const api = axios.create({
  headers: {
    ...(import.meta.env.VITE_ENVIROMENT === 'alpha'
      ? { 'ngrok-skip-browser-warning': 69420 }
      : {}),
  },
})

api.interceptors.request.use(
  (config) => {
    const tokenStorage = localStorage.getItem('auth_access_token')
    const token = tokenStorage ? JSON.parse(tokenStorage) : null

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const auth = useLocalStorage('auth_access_token', '')

    if (
      typeof error.response === 'undefined' &&
      error.config?.url?.includes('/auth/user')
    ) {
      auth.value = null
      window.location.reload()
    }

    if (error.response?.data) {
      Object.values(error.response?.data).map((value: any) => {
        if (value === baseErrors.INVALID_TOKEN) {
          auth.value = null
          window.location.reload()
        }
        if (value === baseErrors.TOKEN_BLACKLISTED) {
          window.location.reload()
        }
      })
    }

    return Promise.reject(error)
  },
)

// Headers
export const setAuthHeader = (token: string) => {
  api.defaults.headers.common['Authorization'] = token
}

export const removeAuthHeader = () => {
  delete api.defaults.headers.common['Authorization']
}

export const setBaseUrl = (baseUrl: string) => {
  api.defaults.baseURL = baseUrl
}

export default api
