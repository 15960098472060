import { type PropType } from 'vue'
import { ModalType } from './composables'

export const authRoles = {
  ADMIN: 'admin',
  WORKER: 'worker',
}

export const roles = [
  {
    label: authRoles.ADMIN,
  },
  {
    label: authRoles.WORKER,
  },
]

export const warehouses = [
  {
    id: 1,
    name: 'General',
  },
]

export const statuses: Record<any, any> = {
  IN_TIME: 'status.ACTIVE',
  DELAYED: 'status.DELAYED',
  DELIVERED: 'status.DELIVERED',
  ACTIVED: 'status.ACTIVED',
  INACTIVED: 'status.INACTIVED',
  PENDING: 'status.PENDING',
}

export const defaultStatuses = {
  IN_TIME: 'RECEIVED',
  DELAYED: 'DELAYED',
  DELIVERED: 'DELIVERED',
  ACTIVED: 'ACTIVED',
  INACTIVED: 'INACTIVED',
  PENDING: 'PENDING',
}

export const statusesList = Object.keys(defaultStatuses)

export const baseProps = {
  isOpen: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<ModalType>,
    default: 'add',
  },
}

export const months = {
  es: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
}

export const defaultPagination = {
  current_page: 1,
  from: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
}

export const recordActions = {
  active: 'active',
  inactive: 'inactive',
  delivery: 'delivery',
} as const

export const receptionsStatuses = [
  { id: 8, name: 'APPLIED', color: 'bg-green-50 text-green-600' },
  { id: 9, name: 'PENDING', color: 'bg-orange-50 text-orange-600' },
]

export const defaultOrder = {
  icon: 'pi-arrow-up',
  name: 'Fecha de creación',
  value: 'created_at,asc',
}

export const dayNames = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
]
export const shortMonthNames = [
  'ene',
  'feb',
  'mar',
  'abr',
  'may',
  'jun',
  'jul',
  'ago',
  'sep',
  'oct',
  'nov',
  'dic',
]

export const indicators = [
  {
    name: 'A tiempo',
    id: 10,
  },
  {
    name: 'Día de entrega',
    id: 11,
  },
  {
    name: 'Retrasado',
    id: 12,
  },
]
