import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import type { TOrder } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { Pagination, TRecordAction } from '@/core/types'
import { useRoute, useRouter } from 'vue-router'
import { useOrderAttachmentsStore } from '../../store/useOrderAttachmentsStore'
import { storeToRefs } from 'pinia'
import { useAttachmentQuery } from '../query/useAttachmentQuery'

const [useProvideModule, useModule] = createInjectionState(() => {
  const filesToUpload = ref<File[]>([])
  const form = ref<TOrder>({})
  const orderId = ref()
  const filters = ref<ToQueryStringFilters>({
    param: '',
    status_id: [],
    dates: {
      created_at: [],
      eta_at: [],
    },
    order_by: 'created_at,desc',
  })

  const router = useRouter()
  const route = useRoute()

  const attachmentsStore = useOrderAttachmentsStore()
  const { attachments } = storeToRefs(attachmentsStore)

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['id', 'folio', 'customer.full_name', 'customer.email'],
  })

  const paginationModule = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: paginationModule.currentPage,
    filterParams: moduleQueryParams.queryParams,
    enabled: {
      loadRecords: computed(() => route.name === 'orders'),
      loadRecord: computed(() => false),
      customersCatalog: computed(() => route.name === 'ordersCreate'),
    },
    handlers: {
      onDataChange: (pagination: Pagination) => {
        paginationModule.setPagination(pagination)
      },
    },
  })

  const attachmentQuery = useAttachmentQuery({
    filterParams: computed(() => ''),
    parentId: computed(() => orderId.value),
    enabled: {
      loadRecords: computed(() => !!orderId.value && !attachments.value.length),
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at', 'id'],
  })

  const onAdd = async (payload: TOrder) => {
    await moduleQuery.addRecord.mutateAsync(payload).then((order) => {
      orderId.value = order.id
      onAddAttachments()
    })
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const onAddAttachments = async () => {
    if (attachments.value.length) {
      const totalSize =
        attachments.value.reduce((prev, curr) => prev + curr.size, 0) / 1000000
      if (totalSize < 21) {
        const formattedFiles = attachments.value.map((file: File) => ({
          file,
        }))

        await attachmentQuery.addRecord.mutateAsync({
          attachments: formattedFiles,
        })
      }
      attachmentsStore.resetStore()
    }

    onRedirectToHome()
  }

  const onRedirectToHome = () => {
    router.push({
      name: 'orders',
    })
  }
  const isCreate = computed(() => route.name === 'ordersCreate')
  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)
  const model = computed(() => moduleQuery.loadRecord?.data?.value)
  const customersCatalog = computed(
    () => moduleQuery.customersCatalog.data.value,
  )
  const isLoading = computed(() => {
    if (isCreate.value) {
      return moduleQuery.addRecord.isLoading.value
    }
    return (
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value
    )
  })

  const isUploadingFiles = computed(
    () => attachmentQuery.addRecord.isLoading.value && !!orderId.value,
  )

  return {
    model,
    tableData,
    filters,
    orderByOptions,
    form,
    paginationModule: toReactive(paginationModule),
    isLoading,
    isUploadingFiles,
    customersCatalog,
    filesToUpload,
    onAction,
    onAdd,
  }
})

export { useProvideModule, useModule }
