import { createAuth } from 'vue-auth3'
import api from '../axios'
import authBearerToken from 'vue-auth3/drivers/auth/bearer-token'
import authBearer from 'vue-auth3/drivers/auth/bearer'
import router from '../router'
import { settings } from '@/core/settings/DynamicSettings'

export const auth = createAuth({
  fetchData: {
    url: '/auth/user',
  },
  refreshToken: {
    url: '/auth/refresh',
    enabled: true,
  },
  logoutData: {
    url: '/auth/logout',
    redirect: '/auth/login',
  },
  loginData: {
    url: '/auth/login',
    staySignedIn: true,
    fetchUser: true,
  },
  plugins: {
    router,
  },
  drivers: {
    http: {
      request: api,
    },
    auth: settings.ENABLE_MSW ? authBearerToken : authBearer,
  },
  authRedirect: 'auth/login',
  rolesKey: 'view_roles',
  tokenDefaultKey: 'auth_access_token',
})
